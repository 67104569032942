import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const StyledContainer = styled.div`
  min-height: 100vh;
  background: url(${require('../assets/man.png')}) no-repeat -250px bottom,
    linear-gradient(243deg, #148be3 0%, #520d96 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 812px) {
    background: url(${require('../assets/man.png')}) no-repeat -400px bottom,
      linear-gradient(243deg, #148be3 0%, #520d96 100%) 0% 0% no-repeat padding-box;
  }
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 16px 128px;

  @media (max-width: 812px) {
    padding: 16px;
  }
`

const Title = styled.h1`
  font-size: 25px;
  color: #fff;
  max-width: 179px;

  @media (max-width: 812px) {
    font-size: 18px;
  }
`

const Menu = styled.ul`
  display: flex;
  list-style: none;
`

const Item = styled.li`
  &:not(:last-child) {
    padding-right: 37px;
  }
`

const StyledLink = styled(Link)`
  font-size: 21px;
  color: #fff;
  text-decoration: none;

  @media (max-width: 812px) {
    font-size: 14px;
  }

  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-bottom: 110px;
  margin-right: 130px;
  align-items: flex-end;

  @media (max-width: 812px) {
    margin-right: 0;
    align-self: center;
    align-items: center;
  }
`

const ContentTitle = styled.h2`
  color: #fff;
  text-align: right;
  font-size: 44px;
  max-width: 415px;
  margin-bottom: 26px;

  @media (max-width: 812px) {
    text-align: center;
    font-size: 30px;
    padding: 0px 10px;
  }
`

const Text = styled.span`
  color: #fff;
  max-width: 551px;
  text-align: right;
  font-size: 21px;
  margin-bottom: 70px;

  @media (max-width: 812px) {
    text-align: center;
    padding: 0px 10px;
  }
`

const Button = styled(Link)`
  border-radius: 35px;
  background-color: #fff;
  color: #148be3;
  font-size: 22px;
  padding: 13px 108px;
  cursor: pointer;

  @media (max-width: 812px) {
    font-size: 18px;
    padding: 13px 80px;
  }

  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`

const TextMenu = styled.span`
  font-size: 21px;
  color: #fff;
`

const MenuIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  margin-top: 12px;
`

interface ContainerProps {}

const HeaderSection: React.FC<ContainerProps> = () => (
  <StyledContainer>
    <Header>
      <Title>New Trend Contábil</Title>
      <Menu>
        <Item>
          <StyledLink to="#services">Serviços</StyledLink>
        </Item>
        <Item>
          <StyledLink to="#contact">Entre em contato</StyledLink>
        </Item>
        <Item>
          <MenuIcon src={require('../assets/phone.png')} />
          <TextMenu>(71) 3141-2000</TextMenu>
        </Item>
      </Menu>
    </Header>
    <Content>
      <ContentTitle>Sua contabilidade Online & Ágil!</ContentTitle>
      <Text>Sua contabilidade online na palma da mão. Foque no seu negócio, deixe nós cuidarmos de sua contabilidade</Text>
      <Button to="#contact">Contrate agora</Button>
    </Content>
  </StyledContainer>
)

export default HeaderSection
