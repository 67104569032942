import * as React from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled.div`
  min-height: 80vh;
  background: #5a5a5a;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h3`
  font-size: 35px;
  color: #ffffff;
  margin-top: 35px;
`

const Text = styled.span`
  margin-top: 11px;
  font-size: 18px;
  color: #ffffff;
  max-width: 551px;
  text-align: center;
  margin-bottom: 16px;
`

const TextInput = styled.input`
  max-width: 808px;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border: 1px solid #fff;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 10px;
  color: #fff;
`

const TextArea = styled.textarea`
  resize: none;
  max-width: 808px;
  width: 100%;
  background: transparent;
  box-shadow: none;
  border: 1px solid #fff;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 15px;
  color: #fff;
  min-height: 200px;
`

const Button = styled.button`
  border-radius: 35px;
  background-color: #148be3;
  color: #fff;
  font-size: 22px;
  padding: 10px 50px;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`

interface ContainerProps {}

const ContactSection: React.FC<ContainerProps> = () => (
  <StyledContainer id="contact">
    <Title>Entre em contato</Title>
    <Text>Fale com um contador</Text>
    <form action="https://formspree.io/mnqjenzr" method="POST">
      <TextInput type="text" name="name" placeholder="Nome completo" />
      <TextInput type="email" name="_replyto" placeholder="Email" />
      <TextArea name="message" placeholder="Fale conosco" />
      <Button type="submit">Enviar</Button>
    </form>
  </StyledContainer>
)

export default ContactSection
