import * as React from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled.footer`
  min-height: 10vh;
  background: #5a5a5a;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Text = styled.span`
  max-width: 630px;
  padding: 0 20px;
  text-align: center;
  color: #fff;
  font-size: 12px;
`

interface ContainerProps {}

const FooterSection: React.FC<ContainerProps> = () => (
  <StyledContainer id="footer">
    <Text>
      Rua Frederico Simões, 98, Caminho das Árvores, EDF Advanced Trade, 5º andar, Salvador/BA. CEP 41820-774. Contato (71) 3141-2000 / (71)
      99309-4453 WhatsApp
    </Text>
  </StyledContainer>
)

export default FooterSection
