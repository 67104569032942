import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const StyledContainer = styled.div`
  min-height: 70vh;
  background: url(${require('../assets/woman.png')}) no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 126px;

  @media (max-width: 812px) {
    margin-right: 0;
  }
`

const Title = styled.h3`
  text-align: right;
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  max-width: 436px;
  margin-bottom: 26px;

  @media (max-width: 812px) {
    text-align: center;
    font-size: 30px;
  }
`

const Text = styled.span`
  text-align: right;
  font-size: 26px;
  color: #fff;
  max-width: 416px;
  margin-bottom: 26px;

  @media (max-width: 812px) {
    text-align: center;
    font-size: 20px;
  }
`

const Button = styled(Link)`
  border-radius: 35px;
  background-color: #fff;
  color: #148be3;
  font-size: 22px;
  padding: 13px 108px;
  cursor: pointer;

  @media (max-width: 812px) {
    padding: 13px 20px;
    font-size: 20px;
    text-align: center;
    align-self: center;
    max-width: 250px;
  }

  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`

interface ContainerProps {}

const FeaturesSection: React.FC<ContainerProps> = () => (
  <StyledContainer>
    <Content>
      <Title>Pra quê se preocupar com burocracias?</Title>
      <Text>Cresça seu negócio! Já as burocracias, a gente resolve.</Text>
      <Button to="#contact">Conheça nossos planos</Button>
    </Content>
  </StyledContainer>
)

export default FeaturesSection
