import * as React from 'react'
import styled from '@emotion/styled'

const StyledContainer = styled.div`
  min-height: 50vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  color: #707070;
  text-align: center;
  font-size: 35px;
  margin-top: 57px;
  margin-bottom: 90px;

  @media (max-width: 812px) {
    font-size: 25px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 116px;
  margin-left: 255px;
  margin-right: 255px;

  @media (max-width: 959px) {
    margin: 0 100px 0 100px;
  }

  @media (max-width: 812px) {
    margin: 0;
    flex-direction: column;
    align-items: center;
  }
`

const Circle = styled.div`
  background-color: #e0e0e0;
  width: 129px;
  height: 129px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 812px) {
    margin-bottom: 20px;
  }
`

const Icon = styled.img``

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.span`
  color: #707070;
  margin: 5px 0;
`

interface ContainerProps {}

const ServicesSection: React.FC<ContainerProps> = () => (
  <StyledContainer id="services">
    <Title>Contabilidade Online e ágil, na palma da sua mão!</Title>
    <Content>
      <Box>
        <Circle>
          <Icon src={require('../assets/company.png')} />
        </Circle>
        <Text>Já tenho empresas</Text>
      </Box>
      <Box>
        <Circle>
          <Icon src={require('../assets/open-company.png')} />
        </Circle>
        <Text>Abra sua empresa grátis</Text>
      </Box>
      <Box>
        <Circle>
          <Icon src={require('../assets/mei.png')} />
        </Circle>
        <Text>Sou MEI</Text>
      </Box>
    </Content>
  </StyledContainer>
)

export default ServicesSection
