import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

import HeaderSection from '../components/HeaderSection'
import ServicesSection from '../components/ServicesSection'
import FeaturesSection from '../components/FeaturesSection'
import PriceSection from '../components/PriceSection'
import StatementSection from '../components/StatementSection'
import ContactSection from '../components/ContactSection'
import FooterSection from '../components/FooterSection'

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <HeaderSection />
        <ServicesSection />
        <FeaturesSection />
        <PriceSection />
        {/* <StatementSection /> */}
        <ContactSection />
        <FooterSection />
      </Container>
    </Page>
  </IndexLayout>
)

export default IndexPage
