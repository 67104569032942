import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const StyledContainer = styled.div`
  min-height: 70vh;
  background: url(${require('../assets/cel.png')}) no-repeat right bottom,
    transparent linear-gradient(62deg, #d3d3d3 0%, #6a6a6a 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 959px) {
    background: url(${require('../assets/cel.png')}) no-repeat 450px bottom,
      transparent linear-gradient(62deg, #d3d3d3 0%, #6a6a6a 100%) 0% 0% no-repeat padding-box;
  }

  @media (max-width: 812px) {
    background: transparent linear-gradient(62deg, #d3d3d3 0%, #6a6a6a 100%) 0% 0% no-repeat padding-box;
    align-items: center;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 130px;

  @media (max-width: 812px) {
    margin-left: 0px;
    text-align: center;
    align-items: center;
  }
`

const Title = styled.h3`
  font-size: 35px;
  font-weight: bold;
  color: #1a62b7;
  max-width: 312px;
`

const Price = styled.span`
  font-size: 55px;
  color: #1a62b7;
  margin-bottom: 26px;
`

const Price2 = styled.span`
  font-size: 105px;
  color: #1a62b7;
`

const Button = styled(Link)`
  border-radius: 35px;
  background-color: #148be3;
  color: #fff;
  font-size: 22px;
  padding: 13px 108px;
  cursor: pointer;

  @media (max-width: 812px) {
    padding: 13px 40px;
  }

  &:hover {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
`

interface ContainerProps {}

const PriceSection: React.FC<ContainerProps> = () => (
  <StyledContainer>
    <Content>
      <Title>Planos a partir de</Title>
      <Price>
        R$<Price2>99</Price2>,00
      </Price>
      <Button to="#contact">Entre em contato</Button>
    </Content>
  </StyledContainer>
)

export default PriceSection
